html, body, #root{
	height: 100%;
	width: 100%;
}

body {
  margin: 0;
  padding: 0;
	background-color: #536878;
	position: fixed;
}

.chart{
	margin: 10px auto;
}

.segoe{
	font-family: "Segoe UI", Helvetica, sans-serif;
}

.container-fluid{
	position:relative;
}


.progress{
	position: absolute;
	top: 0;
}

.clickable{
	cursor: pointer; 
}

.input + .toggle {
	top: 5px;
}

.input:checked + .toggle {
  background-color: #32c17e;
}
.input:checked + .toggle:after {
  background-color: #32c17e;
}